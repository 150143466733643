@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Alkatra:wght@400;500;600;700&family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700&family=Roboto:wght@300;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alkatra:wght@400;500;600;700&family=Montserrat:wght@200;300;400;500;600;700;800;900&family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700&family=Roboto:wght@300;500;700;900&display=swap");

html {
  scroll-behavior: smooth;
}

.header {
  background: linear-gradient(rgba(6, 10, 7, 0.712), rgba(9, 9, 19, 0.589)),
    url(../public/imgs/galeri/17.jpg) center/cover no-repeat;
  h2,
  h3 {
    text-shadow: 0px 2px 15px rgba(0, 0, 0, 0.582);
  }
}

.about {
  background: linear-gradient(rgba(6, 10, 7, 0.712), rgba(9, 9, 19, 0.589)),
    url(../public/imgs/cc4.jpg) center/cover no-repeat;

  // clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0% 85%);
}

.events {
  background: linear-gradient(rgba(6, 10, 7, 0.712), rgba(9, 9, 19, 0.589)),
    url(../public/imgs/race2.jpg) center/cover no-repeat;
}

.cup-banner,
.tetor {
  background: linear-gradient(rgba(6, 10, 7, 0.767), rgba(9, 9, 19, 0.637)),
    url(../public/imgs/gara/5.jpg) center/cover no-repeat;
  &:hover {
    background: linear-gradient(rgba(6, 10, 7, 0.712), rgba(9, 9, 19, 0.589)),
      url(../public/imgs/gara/5.jpg) center/cover no-repeat;
  }
}

.foto {
  background: linear-gradient(rgba(6, 10, 7, 0.712), rgba(9, 9, 19, 0.589)),
    url(../public/imgs/gara/13.jpg) center/cover no-repeat;
  &:hover {
    background: linear-gradient(rgba(6, 10, 7, 0.63), rgba(9, 9, 19, 0.479)),
      url(../public/imgs/gara/13.jpg) center/cover no-repeat;
  }
}

.video {
  background: linear-gradient(rgba(6, 10, 7, 0.712), rgba(9, 9, 19, 0.589)),
    url(../public/imgs/gara/19.jpg) center/cover no-repeat;
  &:hover {
    background: linear-gradient(rgba(6, 10, 7, 0.63), rgba(9, 9, 19, 0.479)),
      url(../public/imgs/gara/19.jpg) center/cover no-repeat;
  }
}

.gallery {
  background: linear-gradient(rgba(6, 10, 7, 0.712), rgba(9, 9, 19, 0.589)),
    url(../public/imgs/gallery.jpg) center/cover no-repeat;
}

.galery-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1.5fr));
  grid-gap: 10px;

  img {
    width: 100%;
    height: 17rem;
    object-fit: cover;
  }
}

.foto-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1.5fr));
  grid-gap: 10px;

  img {
    width: 100%;
    height: 18rem;
    object-fit: cover;
  }
}
.video-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1.5fr));
  grid-gap: 15px;

  video {
    width: 100%;
    height: 15rem;
    object-fit: cover;
  }
}
.event-banner {
  background: linear-gradient(rgba(6, 10, 7, 0.712), rgba(9, 9, 19, 0.589)),
    url(../public/imgs/banner-event.webp) top/cover no-repeat;
  h2,
  h3 {
    text-shadow: 0px 2px 15px rgba(0, 0, 0, 0.582);
  }
  .button {
    transition: 0.3s;
  }

  .button:hover {
    transform: scale(1.1);
  }
}

.trailmap {
  background: linear-gradient(rgba(6, 10, 7, 0.938), rgba(0, 0, 0, 0.877)),
    url(../public/imgs/4.jpg) top/cover no-repeat;
  h2,
  h3 {
    text-shadow: 0px 2px 15px rgba(0, 0, 0, 0.582);
  }
}

.footer {
  background: linear-gradient(
      rgba(247, 250, 247, 0.651),
      rgba(238, 235, 235, 0.623)
    ),
    url(../public/imgs/footer.jpg) top/cover no-repeat;
  h2,
  h3 {
    text-shadow: 0px 2px 15px rgba(0, 0, 0, 0.582);
  }
}

.hd-img {
  clip-path: polygon(14% 0, 100% 0%, 84% 100%, 0% 100%);
}

.info-img,
.event-imgs {
  clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%);
}

.event2024 {
  background: linear-gradient(rgba(6, 10, 7, 0.712), rgba(9, 9, 19, 0.589)),
    url(../public/imgs/cc.jpg) top/cover no-repeat;
}

.banner-gara-2024 {
  background: linear-gradient(rgba(6, 10, 7, 0.712), rgba(9, 9, 19, 0.589)),
    url(../public/imgs/8.jpg) center/cover no-repeat;
}
